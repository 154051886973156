const binding = { modules: {}, dataActions: {} };

    (binding.modules['vc'] = {
        c: () => require('partner/themes/vc/vc.tsx'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['active-image','b2b-requests-status','business-organization-list','buybox','cart-icon','cart','content-block','header','image-list','navigation-menu','order-template-list','promo-banner','quickview','ratings-histogram','reviews-list','search-result-container','search','sign-in','store-selector','text-block','write-review'],
        iNM: false,
        ns: '__local__',
        n: 'vc',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'vc.theme.settings.json',
        md: 'src/themes/vc'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };